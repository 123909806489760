import Vue from "vue";
import Vuex from "vuex";

import account from "./modules/account";
import authentication from "./modules/authentication";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    account,
    authentication,
  },
  strict: process.env.NODE_ENV !== "production",
});

export default store;
