var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`rn-callto-action ${_vm.checkStyleType}`,style:({ 'background-image': `url(${_vm.backgroundImage})` }),attrs:{"data-black-overlay":_vm.setOverlay}},[_c('div',{staticClass:"container"},[(_vm.styleType === 1)?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content text-center"},[_c('h2',{staticClass:"title",attrs:{"data-aos":"fade-up","data-aos-delay":"60"},domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('h6',{staticClass:"subtitle",attrs:{"data-aos":"fade-up","data-aos-delay":"60"},domProps:{"innerHTML":_vm._s(_vm.subtitle)}}),_c('div',{staticClass:"call-to-btn",attrs:{"data-aos":"fade-up","data-aos-delay":"60"}},[_c('a',{staticClass:"btn-default",attrs:{"href":`https://wa.me/${_vm.funnelPublic.whatsApp}?text=${_vm.$t(
                      'text.funnel-whatsapp-message',
                      {
                        value: _vm.funnelPublic.name,
                      }
                    )}`,"target":"_blank"}},[_vm._v(_vm._s(_vm.btnText))])])])])])])]:_vm._e(),(_vm.styleType === 2)?[_c('div',{staticClass:"row row--30 align-items-center"},[_c('div',{staticClass:"col-lg-10 offset-lg-1"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content text-center"},[_c('h2',{staticClass:"title mb--0",attrs:{"data-aos":"fade-up","data-aos-delay":"60"},domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('p',{attrs:{"data-aos":"fade-up","data-aos-delay":"60"},domProps:{"innerHTML":_vm._s(_vm.subtitle)}}),_c('div',{staticClass:"call-to-btn text-center",attrs:{"data-aos":"fade-up","data-aos-delay":"60"}},[_c('a',{staticClass:"btn-default btn-icon",attrs:{"href":`https://wa.me/${_vm.funnelPublic.whatsApp}?text=${_vm.$t(
                      'text.funnel-whatsapp-message',
                      {
                        value: _vm.funnelPublic.name,
                      }
                    )}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.btnText)+" "),_c('Icon',{staticClass:"size-20",attrs:{"name":"arrow-right"}})],1)])])])])])]:_vm._e(),(_vm.styleType === 3)?[_c('div',{staticClass:"row row--0 align-items-center content-wrapper",attrs:{"data-aos":"fade-up","data-aos-delay":"60"}},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content text-start"},[_c('h2',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('h6',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(_vm.subtitle)}})])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"call-to-btn text-start text-lg-end"},[_c('a',{staticClass:"btn-default",attrs:{"href":`https://wa.me/${_vm.funnelPublic.whatsApp}?text=${_vm.$t(
                  'text.funnel-whatsapp-message',
                  {
                    value: _vm.funnelPublic.name,
                  }
                )}`,"target":"_blank"}},[_vm._v(_vm._s(_vm.btnText))])])])])]:_vm._e(),(_vm.styleType === 4)?[_c('div',{staticClass:"row row--0 align-items-center content-wrapper",attrs:{"data-aos":"fade-up","data-aos-delay":"60"}},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content text-start"},[_c('h2',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('h6',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(_vm.subtitle)}})])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"call-to-btn text-start text-lg-end"},[_c('a',{staticClass:"btn-default",attrs:{"href":`https://wa.me/${_vm.funnelPublic.whatsApp}?text=${_vm.$t(
                  'text.funnel-whatsapp-message',
                  {
                    value: _vm.funnelPublic.name,
                  }
                )}`,"target":"_blank"}},[_vm._v(_vm._s(_vm.btnText))])])])])]:_vm._e(),(_vm.styleType === 5)?[_c('div',{staticClass:"row row--0 align-items-center content-wrapper theme-shape",attrs:{"data-aos":"fade-up","data-aos-delay":"60"}},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content text-center"},[_c('h2',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('h6',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(_vm.subtitle)}}),_c('div',{staticClass:"call-to-btn text-center"},[_c('a',{staticClass:"btn-default btn-icon",attrs:{"href":`https://wa.me/${_vm.funnelPublic.whatsApp}?text=${_vm.$t(
                      'text.funnel-whatsapp-message',
                      {
                        value: _vm.funnelPublic.name,
                      }
                    )}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.btnText)+" "),_c('Icon',{staticClass:"size-20",attrs:{"name":"arrow-right"}})],1)])])])])])]:_vm._e(),(_vm.styleType === 6)?[_c('div',{staticClass:"row row--0 align-items-center content-wrapper"},[_c('div',{staticClass:"col-lg-8 col-12"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content text-start",attrs:{"data-aos":"fade-up","data-aos-delay":"60"}},[_c('h2',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.title)}})])])]),_c('div',{staticClass:"col-lg-4 col-12"},[_c('div',{staticClass:"call-to-btn text-start text-lg-end mt_md--20 mt_sm--20",attrs:{"data-aos":"fade-up","data-aos-delay":"60"}},[_c('a',{staticClass:"btn-default",attrs:{"href":`https://wa.me/${_vm.funnelPublic.whatsApp}?text=${_vm.$t(
                  'text.funnel-whatsapp-message',
                  {
                    value: _vm.funnelPublic.name,
                  }
                )}`,"target":"_blank"}},[_vm._v(_vm._s(_vm.btnText))])])])])]:_vm._e(),(_vm.styleType === 7)?[_c('div',{staticClass:"row row--0 align-items-center content-wrapper"},[_c('div',{staticClass:"col-lg-8 col-md-8"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content text-start"},[_c('Logo'),_c('p',{staticClass:"subtitle",attrs:{"data-aos":"fade-up","data-aos-delay":"60"},domProps:{"innerHTML":_vm._s(_vm.subtitle)}})],1)])]),_c('div',{staticClass:"col-lg-4 col-md-4"},[_c('div',{staticClass:"call-to-btn text-start mt_sm--20 text-md-end",attrs:{"data-aos":"fade-up","data-aos-delay":"60"}},[_c('a',{staticClass:"btn-default btn-icon",attrs:{"href":`https://wa.me/${_vm.funnelPublic.whatsApp}?text=${_vm.$t(
                  'text.funnel-whatsapp-message',
                  {
                    value: _vm.funnelPublic.name,
                  }
                )}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.btnText)+" "),_c('Icon',{staticClass:"size-20",attrs:{"name":"arrow-right"}})],1)])])])]:_vm._e(),(_vm.styleType === 8)?[_c('div',{staticClass:"row row--0 align-items-center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content text-center"},[_c('h2',{staticClass:"title",attrs:{"data-aos":"fade-up","data-aos-delay":"60"},domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('h6',{staticClass:"subtitle",attrs:{"data-aos":"fade-up","data-aos-delay":"60"},domProps:{"innerHTML":_vm._s(_vm.subtitle)}}),_c('div',{staticClass:"call-to-btn text-center mt--30",attrs:{"data-aos":"fade-up","data-aos-delay":"60"}},[_c('a',{staticClass:"btn-default btn-icon",attrs:{"href":`https://wa.me/${_vm.funnelPublic.whatsApp}?text=${_vm.$t(
                      'text.funnel-whatsapp-message',
                      {
                        value: _vm.funnelPublic.name,
                      }
                    )}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.btnText)+" "),_c('Icon',{staticClass:"size-20",attrs:{"name":"arrow-right"}})],1)])])])])])]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }