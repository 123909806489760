<template>
  <div class="logo">
    <a href="https://www.rrenova.com" target="_blank">
      <img class="logo-light" :src="lightLogo" alt="Corporate Logo" />
      <img class="logo-dark" :src="darkLogo" alt="Corporate Logo" />
    </a>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    lightLogo: {
      type: String,
      default: function () {
        return require(`@/assets/images/logo/logo.png`);
      },
    },
    darkLogo: {
      type: String,
      default: function () {
        return require(`@/assets/images/logo/logo-dark.png`);
      },
    },
  },
};
</script>