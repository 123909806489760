var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row--15 service-wrapper"},_vm._l(([
        {
          image: require(`@/assets/images/business-opportunity.png`),
          title: this.$t('text.business-opportunity'),
          description: this.$t('text.business-opportunity-description'),
        },
        {
          image: require(`@/assets/images/products-and-services.png`),
          title: this.$t('text.products-and-services'),
          description: this.$t('text.products-and-services-description'),
        },
        {
          image: require(`@/assets/images/fintech-and-cryptoactives.png`),
          title: this.$t('text.fintech-and-cryptoactives'),
          description: this.$t('text.fintech-and-cryptoactives-description'),
        },
      ]),function(service,index){return _c('div',{key:index,staticClass:"col-lg-4 col-md-6 col-sm-6 col-12"},[_c('div',{attrs:{"data-aos":"fade-up","data-aos-delay":"70"}},[_c('div',{class:`service ${_vm.serviceStyle} ${_vm.checkTextAlign}`},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":service.image,"alt":"Service Images"}})]),_c('div',{staticClass:"content"},[_c('h4',{domProps:{"innerHTML":_vm._s(service.title)}}),_c('p',{staticClass:"description b1 color-gray mb--0",domProps:{"innerHTML":_vm._s(service.description)}})])])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }