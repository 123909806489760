<template>
  <Layout
    :show-top-header="true"
    :topbar-style="2"
    :footer-style="2"
    :show-footer="false"
    :show-theme-switcher-button="true"
    :header-transparency="true"
    :show-copyright="false"
  >
    <!-- Start Error-Area Area  -->
    <div class="error-area ptb--200 ptb_sm--60 ptb_md--80">
      <div class="container">
        <div class="row align-item-center">
          <div class="col-lg-12">
            <div class="error-inner">
              <h1>404</h1>
              <h2 class="title">
                {{ $t("text.something-not-right") }}
              </h2>
              <p class="description">
                {{ $t("text.page-not-found") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Error-Area Area  -->
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";

export default {
  name: "Error404",
  components: { Layout },
};
</script>