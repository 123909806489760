<template>
  <!-- Start Copy Right Area  -->
  <div class="copyright-area copyright-style-one">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-8 col-sm-12 col-12">
          <div class="copyright-left">
            <ul class="ft-menu link-hover">
              <li>
                <a href="https://www.rrenova.com" target="_blank">
                  {{ $t("text.official-website") }}
                </a>
              </li>
              <li>
                <a
                  :href="`https://office.rrenova.com/sign-up?ucode=${$route.params.ucode}`"
                  target="_blank"
                >
                  {{ $t("text.virtual-office") }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-4 col-sm-12 col-12">
          <div class="copyright-right text-center text-lg-end">
            <p class="copyright-text">
              © 2021 - {{ new Date().getFullYear() }} RRENOVA INTERNATIONAL LLC.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Copy Right Area  -->
</template>

<script>
export default {
  name: "Copyright",
};
</script>