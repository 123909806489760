var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.lodash.isNil(_vm.funnelPublic))?_c('Layout',{attrs:{"show-top-header":true,"topbar-style":2,"footer-style":2,"show-footer":false,"show-theme-switcher-button":true,"header-transparency":true}},[_c('div',{staticClass:"slider-area slider-style-2 variation-3 height-850 bg_image bg_image_fixed",style:({
        'background-image': `url(${require(`@/assets/images/bg.png`)})`,
      })},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row--30 align-items-center"},[_c('div',{staticClass:"order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30"},[_c('div',{staticClass:"inner text-left"},[_c('h1',{staticClass:"title",attrs:{"data-aos":"fade-up","data-aos-delay":"100"}},[_vm._v(" "+_vm._s(_vm.$t("text.funnel-greeting"))+" "),_c('br'),_c('span',{staticClass:"theme-gradient"},[_vm._v(_vm._s(_vm.funnelPublic.name)+"!")])]),_c('p',{staticClass:"description",attrs:{"data-aos":"fade-up","data-aos-delay":"150"}},[_vm._v(" "+_vm._s(_vm.$t("text.funnel-greeting-description"))+" ")]),_c('div',{staticClass:"button-group mt--40 mt_sm--20",attrs:{"data-aos":"fade-up","data-aos-delay":"200"}},[_c('a',{staticClass:"btn-default round btn-icon",attrs:{"href":`https://wa.me/${_vm.funnelPublic.whatsApp}?text=${_vm.$t(
                    'text.funnel-whatsapp-message',
                    {
                      value: _vm.funnelPublic.name,
                    }
                  )}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("button.get-in-touch"))+" "),_c('Icon',{attrs:{"name":"arrow-right","size":"20","icon-class":"icon"}})],1)])])]),_c('div',{staticClass:"col-lg-6 order-1 order-lg-2"},[_c('Video',{attrs:{"video-data":[
                {
                  thumb: require(`@/assets/images/poster.jpeg`),
                  src: !_vm.lodash.isNil(_vm.funnelPublic.video)
                    ? _vm.funnelPublic.video
                    : 'https://www.youtube.com/watch?v=BAyJMpl_4aE&t=2s',
                },
              ],"column":"col-12"}})],1)])])]),_c('div',{staticClass:"rn-service-area rn-section-gap"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('SectionTitle',{attrs:{"text-align":"center","subtitle":_vm.$t('text.what-does-rrenova-have-for-you'),"title":_vm.$t('text.everything-you-need-in-one-place'),"data-aos":"fade-up"}})],1)]),_c('ServiceThree',{attrs:{"service-style":"service__style--2","text-align":"center"}})],1)]),_c('Separator'),_c('div',{staticClass:"about-area about-style-4 rn-section-gap"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row--40 align-items-center"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"thumbnail",attrs:{"data-aos":"fade-up","data-aos-delay":"250"}},[_c('img',{staticClass:"w-100",attrs:{"src":`${_vm.baseURL}/api/v2/file/do/${_vm.funnelPublic.photo}`}})])]),_c('div',{staticClass:"col-lg-6 mt_md--40 mt_sm--40"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"inner"},[_c('h3',{staticClass:"title",attrs:{"data-aos":"fade-up","data-aos-delay":"310"}},[_vm._v(" "+_vm._s(_vm.$t("text.my-relationship-with-rrenova"))+" ")]),_c('p',{attrs:{"data-aos":"fade-up","data-aos-delay":"310"}},[_vm._v(" "+_vm._s(_vm.funnelPublic.description)+" ")]),_c('div',{staticClass:"about-btn mt--30",attrs:{"data-aos":"fade-up","data-aos-delay":"370"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a',{staticClass:"btn-default round btn-icon",attrs:{"href":`https://wa.me/${
                          _vm.funnelPublic.whatsApp
                        }?text=${_vm.$t('text.funnel-whatsapp-message', {
                          value: _vm.funnelPublic.name,
                        })}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("button.get-in-touch"))+" "),_c('Icon',{attrs:{"name":"arrow-right","size":"20","icon-class":"icon"}})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"text-center"},[_c('ul',{staticClass:"social-icon social-default with-gradient transparent-with-border justify-content-center"},[(!_vm.lodash.isNil(_vm.funnelPublic.facebook))?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.funnelPublic.facebook}},[_c('Icon',{attrs:{"name":"facebook"}})],1)]):_vm._e(),(!_vm.lodash.isNil(_vm.funnelPublic.twitter))?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.funnelPublic.twitter}},[_c('Icon',{attrs:{"name":"twitter"}})],1)]):_vm._e(),(!_vm.lodash.isNil(_vm.funnelPublic.instagram))?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.funnelPublic.instagram}},[_c('Icon',{attrs:{"name":"instagram"}})],1)]):_vm._e(),(!_vm.lodash.isNil(_vm.funnelPublic.linkedin))?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.funnelPublic.linkedin}},[_c('Icon',{attrs:{"name":"linkedin"}})],1)]):_vm._e(),(!_vm.lodash.isNil(_vm.funnelPublic.video))?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.funnelPublic.video}},[_c('Icon',{attrs:{"name":"youtube"}})],1)]):_vm._e()])])])])])])])])])])]),_c('div',{staticClass:"rwt-split-area"},[_c('div',{staticClass:"wrapper"},[_c('Split',{attrs:{"split-data":{
            image: 'grow-with-us.jpeg',
            title: _vm.$t('text.grow-with-us'),
            description: _vm.$t('text.grow-with-us-description'),
            counterData: [
              {
                number: 120000,
                title: _vm.$t('text.registered-users'),
              },
              {
                number: 53000,
                title: _vm.$t('text.licenses'),
              },
              {
                number: 48,
                title: _vm.$t('text.countries'),
              },
              {
                number: 7000000,
                title: _vm.$t('text.transactions'),
              },
            ],
          },"split-style":3}})],1)]),_c('div',{staticClass:"rwt-callto-action-area rn-section-gapBottom",staticStyle:{"margin-top":"125px !important"}},[_c('div',{staticClass:"wrapper"},[_c('CallToAction',{attrs:{"style-type":5,"title":`${_vm.$t('text.call-action-title')}`,"subtitle":`${_vm.$t('text.call-action-subtitle')}`,"btn-text":`${_vm.$t('button.send-message')}`}})],1)]),_c('Separator'),_c('div',{staticClass:"rwt-testimonial-area rn-section-gap"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mb--20"},[_c('div',{staticClass:"col-lg-12"},[_c('SectionTitle',{attrs:{"text-align":"center","subtitle":_vm.$t('text.client-feedback'),"title":_vm.$t('text.our-clients-feedback'),"description":_vm.$t('text.our-clients-feedback-description'),"data-aos":"fade-up"}})],1)]),_c('Testimonial',{attrs:{"testimonial":[
            {
              form: _vm.$t('text.united-states'),
              description: _vm.$t('text.testimonial-5'),
              name: 'Odelys Álvarez',
              subtitle: '',
              image: 'testimonial/5.jpeg',
            },
            {
              form: _vm.$t('text.dominican-republic'),
              description: _vm.$t('text.testimonial-4'),
              name: 'Isidro Céspedes',
              subtitle: '',
              image: 'testimonial/4.jpeg',
            },
            {
              form: _vm.$t('text.united-states'),
              description: _vm.$t('text.testimonial-1'),
              name: 'Margarita Vidal y Marcelo Retamal',
              subtitle: '',
              image: 'testimonial/1.jpeg',
            },
            {
              form: 'Colombia',
              description: _vm.$t('text.testimonial-3'),
              name: 'Abraham Moreno',
              subtitle: '',
              image: 'testimonial/3.jpeg',
            },
            {
              form: 'Ecuador',
              description: _vm.$t('text.testimonial-2'),
              name: 'Leida Herrera',
              subtitle: '',
              image: 'testimonial/2.jpeg',
            },
          ],"testimonial-style":5,"data-aos":"fade-up"}})],1)]),_c('Separator'),_c('div',{staticClass:"rwt-team-area rn-section-gap"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 mb--15"},[_c('SectionTitle',{attrs:{"text-align":"center","subtitle":_vm.$t('text.our-partners'),"title":_vm.$t('text.the-corporate-team'),"data-aos":"fade-up"}})],1)]),_c('div',{staticClass:"row row--15"},_vm._l(([
              {
                image: 'team/angel-arias.jpeg',
                name: 'Mr. Angel Arias',
                designation: _vm.$t('text.president'),
              },
              {
                image: 'team/alain-ruiz.jpeg',
                name: 'Msc. Alain Ruiz',
                designation: 'CEO',
              },
              {
                image: 'team/frances-salce.jpeg',
                name: 'Lic. Frances Salcé',
                designation: _vm.$t('text.operational-manager'),
              },
              {
                image: 'team/daniel-quiroz.jpeg',
                name: 'Dr. Daniel Quiroz',
                designation: _vm.$t('text.vice-president'),
              },
            ]),function(teamMember,index){return _c('div',{key:index,staticClass:"col-lg-3 col-md-6 col-12 mt--30"},[_c('Team',{attrs:{"team-member":teamMember,"team-style":3}})],1)}),0)])]),_c('div',{staticClass:"contact-area rn-section-gapBottom"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mb--20"},[_c('div',{staticClass:"col-lg-12"},[_c('SectionTitle',{attrs:{"title":_vm.$t('text.contact-with-me'),"section-title-style":2,"data-aos":"fade-up"}})],1)]),_c('div',{staticClass:"row row--30 mt--40"},[_c('div',{staticClass:"col-lg-6 order-2 oder-lg-1 mt_md--30 mt_sm--30"},[_c('form',{staticClass:"contact-form-1"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subject),expression:"subject"}],attrs:{"type":"text","placeholder":_vm.$t('placeholder.subject'),"required":""},domProps:{"value":(_vm.subject)},on:{"input":function($event){if($event.target.composing)return;_vm.subject=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.body),expression:"body"}],attrs:{"placeholder":_vm.$t('placeholder.message'),"required":""},domProps:{"value":(_vm.body)},on:{"input":function($event){if($event.target.composing)return;_vm.body=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[(
                    !_vm.lodash.isEmpty(_vm.funnelPublic) &&
                    !_vm.lodash.isEmpty(_vm.subject) &&
                    !_vm.lodash.isEmpty(_vm.body)
                  )?[_c('a',{staticClass:"btn-default round",attrs:{"href":`mailto:${_vm.funnelPublic.email}?subject=${_vm.subject}&body=${_vm.body}`}},[_vm._v(" "+_vm._s(_vm.$t("button.send-message"))+" ")])]:[_c('button',{staticClass:"btn-default round",attrs:{"type":"button","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t("button.send-message"))+" ")])]],2)])]),_c('div',{staticClass:"col-lg-6 order-1 oder-lg-2"},[_c('div',{staticClass:"thumbnail"},[_c('img',{staticClass:"radius w-100",attrs:{"src":require("@/assets/images/contact.jpeg"),"alt":"Corporate"}})])])])])])],1):_c('div')
}
var staticRenderFns = []

export { render, staticRenderFns }