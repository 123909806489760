<template>
  <Layout
    v-if="!lodash.isNil(funnelPublic)"
    :show-top-header="true"
    :topbar-style="2"
    :footer-style="2"
    :show-footer="false"
    :show-theme-switcher-button="true"
    :header-transparency="true"
  >
    <!-- Start Slider area -->
    <div
      class="
        slider-area slider-style-2
        variation-3
        height-850
        bg_image bg_image_fixed
      "
      :style="{
        'background-image': `url(${require(`@/assets/images/bg.png`)})`,
      }"
    >
      <div class="container">
        <div class="row row--30 align-items-center">
          <div
            class="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30"
          >
            <div class="inner text-left">
              <h1 class="title" data-aos="fade-up" data-aos-delay="100">
                {{ $t("text.funnel-greeting") }} <br />
                <span class="theme-gradient">{{ funnelPublic.name }}!</span>
              </h1>

              <p class="description" data-aos="fade-up" data-aos-delay="150">
                {{ $t("text.funnel-greeting-description") }}
              </p>
              <div
                class="button-group mt--40 mt_sm--20"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <a
                  class="btn-default round btn-icon"
                  :href="`https://wa.me/${funnelPublic.whatsApp}?text=${$t(
                    'text.funnel-whatsapp-message',
                    {
                      value: funnelPublic.name,
                    }
                  )}`"
                  target="_blank"
                >
                  {{ $t("button.get-in-touch") }}
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 order-1 order-lg-2">
            <Video
              :video-data="[
                {
                  thumb: require(`@/assets/images/poster.jpeg`),
                  src: !lodash.isNil(funnelPublic.video)
                    ? funnelPublic.video
                    : 'https://www.youtube.com/watch?v=BAyJMpl_4aE&t=2s',
                },
              ]"
              column="col-12"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider area -->

    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              :subtitle="$t('text.what-does-rrenova-have-for-you')"
              :title="$t('text.everything-you-need-in-one-place')"
              data-aos="fade-up"
            />
          </div>
        </div>
        <ServiceThree service-style="service__style--2" text-align="center" />
      </div>
    </div>
    <!-- End Service Area -->

    <Separator />

    <!-- Start About Area  -->
    <div class="about-area about-style-4 rn-section-gap">
      <div class="container">
        <div class="row row--40 align-items-center">
          <div class="col-lg-6">
            <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
              <img
                class="w-100"
                :src="`${baseURL}/api/v2/file/do/${funnelPublic.photo}`"
              />
            </div>
          </div>
          <div class="col-lg-6 mt_md--40 mt_sm--40">
            <div class="content">
              <div class="inner">
                <h3 class="title" data-aos="fade-up" data-aos-delay="310">
                  {{ $t("text.my-relationship-with-rrenova") }}
                </h3>

                <p data-aos="fade-up" data-aos-delay="310">
                  {{ funnelPublic.description }}
                </p>

                <div
                  class="about-btn mt--30"
                  data-aos="fade-up"
                  data-aos-delay="370"
                >
                  <div class="row">
                    <div class="col-6">
                      <a
                        class="btn-default round btn-icon"
                        :href="`https://wa.me/${
                          funnelPublic.whatsApp
                        }?text=${$t('text.funnel-whatsapp-message', {
                          value: funnelPublic.name,
                        })}`"
                        target="_blank"
                      >
                        {{ $t("button.get-in-touch") }}
                        <Icon name="arrow-right" size="20" icon-class="icon" />
                      </a>
                    </div>
                    <div class="col-6">
                      <div class="text-center">
                        <ul
                          class="
                            social-icon social-default
                            with-gradient
                            transparent-with-border
                            justify-content-center
                          "
                        >
                          <li v-if="!lodash.isNil(funnelPublic.facebook)">
                            <a target="_blank" :href="funnelPublic.facebook">
                              <Icon name="facebook" />
                            </a>
                          </li>
                          <li v-if="!lodash.isNil(funnelPublic.twitter)">
                            <a target="_blank" :href="funnelPublic.twitter">
                              <Icon name="twitter" />
                            </a>
                          </li>
                          <li v-if="!lodash.isNil(funnelPublic.instagram)">
                            <a target="_blank" :href="funnelPublic.instagram">
                              <Icon name="instagram" />
                            </a>
                          </li>
                          <li v-if="!lodash.isNil(funnelPublic.linkedin)">
                            <a target="_blank" :href="funnelPublic.linkedin">
                              <Icon name="linkedin" />
                            </a>
                          </li>
                          <li v-if="!lodash.isNil(funnelPublic.video)">
                            <a target="_blank" :href="funnelPublic.video">
                              <Icon name="youtube" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End About Area  -->

    <!-- Start Split Area -->
    <div class="rwt-split-area">
      <div class="wrapper">
        <Split
          :split-data="{
            image: 'grow-with-us.jpeg',
            title: $t('text.grow-with-us'),
            description: $t('text.grow-with-us-description'),
            counterData: [
              {
                number: 120000,
                title: $t('text.registered-users'),
              },
              {
                number: 53000,
                title: $t('text.licenses'),
              },
              {
                number: 48,
                title: $t('text.countries'),
              },
              {
                number: 7000000,
                title: $t('text.transactions'),
              },
            ],
          }"
          :split-style="3"
        />
      </div>
    </div>
    <!-- End Split Area -->

    <!-- Start Elements Area -->
    <div
      class="rwt-callto-action-area rn-section-gapBottom"
      style="margin-top: 125px !important"
    >
      <div class="wrapper">
        <CallToAction
          :style-type="5"
          :title="`${$t('text.call-action-title')}`"
          :subtitle="`${$t('text.call-action-subtitle')}`"
          :btn-text="`${$t('button.send-message')}`"
        />
      </div>
    </div>
    <!-- End Elements Area -->

    <Separator />

    <!-- Start Elements Area -->
    <div class="rwt-testimonial-area rn-section-gap">
      <div class="container">
        <div class="row mb--20">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              :subtitle="$t('text.client-feedback')"
              :title="$t('text.our-clients-feedback')"
              :description="$t('text.our-clients-feedback-description')"
              data-aos="fade-up"
            />
          </div>
        </div>
        <Testimonial
          :testimonial="[
            {
              form: $t('text.united-states'),
              description: $t('text.testimonial-5'),
              name: 'Odelys Álvarez',
              subtitle: '',
              image: 'testimonial/5.jpeg',
            },
            {
              form: $t('text.dominican-republic'),
              description: $t('text.testimonial-4'),
              name: 'Isidro Céspedes',
              subtitle: '',
              image: 'testimonial/4.jpeg',
            },
            {
              form: $t('text.united-states'),
              description: $t('text.testimonial-1'),
              name: 'Margarita Vidal y Marcelo Retamal',
              subtitle: '',
              image: 'testimonial/1.jpeg',
            },
            {
              form: 'Colombia',
              description: $t('text.testimonial-3'),
              name: 'Abraham Moreno',
              subtitle: '',
              image: 'testimonial/3.jpeg',
            },
            {
              form: 'Ecuador',
              description: $t('text.testimonial-2'),
              name: 'Leida Herrera',
              subtitle: '',
              image: 'testimonial/2.jpeg',
            },
          ]"
          :testimonial-style="5"
          data-aos="fade-up"
        />
      </div>
    </div>
    <!-- End Elements Area -->

    <Separator />

    <!-- Start Team Area -->
    <div class="rwt-team-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb--15">
            <SectionTitle
              text-align="center"
              :subtitle="$t('text.our-partners')"
              :title="$t('text.the-corporate-team')"
              data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row row--15">
          <div
            class="col-lg-3 col-md-6 col-12 mt--30"
            v-for="(teamMember, index) in [
              {
                image: 'team/angel-arias.jpeg',
                name: 'Mr. Angel Arias',
                designation: $t('text.president'),
              },
              {
                image: 'team/alain-ruiz.jpeg',
                name: 'Msc. Alain Ruiz',
                designation: 'CEO',
              },
              {
                image: 'team/frances-salce.jpeg',
                name: 'Lic. Frances Salcé',
                designation: $t('text.operational-manager'),
              },
              {
                image: 'team/daniel-quiroz.jpeg',
                name: 'Dr. Daniel Quiroz',
                designation: $t('text.vice-president'),
              },
            ]"
            :key="index"
          >
            <Team :team-member="teamMember" :team-style="3" />
          </div>
        </div>
      </div>
    </div>
    <!-- End Team Area -->

    <div class="contact-area rn-section-gapBottom">
      <div class="container">
        <div class="row mb--20">
          <div class="col-lg-12">
            <SectionTitle
              :title="$t('text.contact-with-me')"
              :section-title-style="2"
              data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row row--30 mt--40">
          <div class="col-lg-6 order-2 oder-lg-1 mt_md--30 mt_sm--30">
            <form class="contact-form-1">
              <div class="form-group">
                <input
                  v-model="subject"
                  type="text"
                  :placeholder="$t('placeholder.subject')"
                  required
                />
              </div>

              <div class="form-group">
                <textarea
                  v-model="body"
                  :placeholder="$t('placeholder.message')"
                  required
                >
                </textarea>
              </div>

              <div class="form-group">
                <template
                  v-if="
                    !lodash.isEmpty(funnelPublic) &&
                    !lodash.isEmpty(subject) &&
                    !lodash.isEmpty(body)
                  "
                >
                  <a
                    class="btn-default round"
                    :href="`mailto:${funnelPublic.email}?subject=${subject}&body=${body}`"
                  >
                    {{ $t("button.send-message") }}
                  </a>
                </template>
                <template v-else>
                  <button type="button" class="btn-default round" disabled>
                    {{ $t("button.send-message") }}
                  </button>
                </template>
              </div>
            </form>
          </div>
          <div class="col-lg-6 order-1 oder-lg-2">
            <div class="thumbnail">
              <img
                class="radius w-100"
                src="@/assets/images/contact.jpeg"
                alt="Corporate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>

  <div v-else></div>
</template>

<script>
import Layout from "@/components/common/Layout";
import SectionTitle from "@/components/elements/sectionTitle/SectionTitle";
import Separator from "@/components/elements/separator/Separator";
import Icon from "@/components/icon/Icon";
import { mapGetters } from "vuex";
import axios from "axios";
import Video from "@/components/elements/video/Video";
import ServiceThree from "@/components/elements/service/ServiceThree";
import Split from "@/components/elements/split/Split";
import Team from "@/components/elements/team/Team";
import Testimonial from "@/components/elements/testimonial/Testimonial";
import CallToAction from "@/components/elements/callToAction/CallToAction";

export default {
  data() {
    return {
      subject: "",
      body: "",

      index: null,

      baseURL: `${axios.defaults.baseURL}`,
    };
  },
  methods: {
    accountKYCPublicGet() {
      this.$store
        .dispatch("accountKYCPublicGet", {
          ucode: this.$route.params.ucode,
        })
        .then(() => {
          if (
            this.lodash.isNil(this.funnelPublic) ||
            this.lodash.isNil(this.funnelPublic.name) ||
            this.lodash.isNil(this.funnelPublic.description) ||
            this.lodash.isNil(this.funnelPublic.photo) ||
            this.lodash.isNil(this.funnelPublic.whatsApp)
          ) {
            this.$router.replace({ name: "404" });
          }
        })
        .catch(() => {
          this.$router.replace({ name: "404" });
        });
    },
  },
  computed: {
    ...mapGetters(["funnelPublic"]),
  },
  mounted() {
    this.accountKYCPublicGet();
  },
  components: {
    Layout,
    SectionTitle,
    Separator,
    Icon,
    Video,
    ServiceThree,
    Split,
    Team,
    Testimonial,
    CallToAction,
  },
};
</script>