var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright-area copyright-style-one variation-two"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-4 col-md-7 col-sm-12 col-12"},[_c('div',{staticClass:"copyright-left"},[_c('ul',{staticClass:"ft-menu link-hover"},[_c('li',[_c('a',{attrs:{"href":"https://www.rrenova.com","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("text.official-website"))+" ")])]),_c('li',[_c('a',{attrs:{"href":`https://office.rrenova.com/sign-up?ucode=${_vm.$route.params.ucode}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("text.virtual-office"))+" ")])])])])]),_c('div',{staticClass:"col-lg-4 col-md-5 mt_sm--20"},[(
              !_vm.lodash.isNil(_vm.funnelPublic) &&
              (!_vm.lodash.isNil(_vm.funnelPublic.facebook) ||
                !_vm.lodash.isNil(_vm.funnelPublic.twitter) ||
                !_vm.lodash.isNil(_vm.funnelPublic.instagram) ||
                !_vm.lodash.isNil(_vm.funnelPublic.linkedin) ||
                !_vm.lodash.isNil(_vm.funnelPublic.video))
            )?_c('div',{staticClass:"copyright-center text-center"},[_c('ul',{staticClass:"social-icon social-default color-lessdark with-gradient justify-content-center"},[(!_vm.lodash.isNil(_vm.funnelPublic.facebook))?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.funnelPublic.facebook}},[_c('Icon',{attrs:{"name":"facebook"}})],1)]):_vm._e(),(!_vm.lodash.isNil(_vm.funnelPublic.twitter))?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.funnelPublic.twitter}},[_c('Icon',{attrs:{"name":"twitter"}})],1)]):_vm._e(),(!_vm.lodash.isNil(_vm.funnelPublic.instagram))?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.funnelPublic.instagram}},[_c('Icon',{attrs:{"name":"instagram"}})],1)]):_vm._e(),(!_vm.lodash.isNil(_vm.funnelPublic.linkedin))?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.funnelPublic.linkedin}},[_c('Icon',{attrs:{"name":"linkedin"}})],1)]):_vm._e(),(!_vm.lodash.isNil(_vm.funnelPublic.video))?_c('li',[_c('a',{attrs:{"target":"_blank","href":_vm.funnelPublic.video}},[_c('Icon',{attrs:{"name":"youtube"}})],1)]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20"},[_c('div',{staticClass:"copyright-right text-center text-lg-end"},[_c('p',{staticClass:"copyright-text"},[_vm._v(" © 2021 - "+_vm._s(new Date().getFullYear())+" RRENOVA INTERNATIONAL LLC. ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }