import Account from "@/service/account";

const state = {
  account: sessionStorage.getItem("account"),
  funnel: null,
  funnelPublic: null,
};

const getters = {
  account: (state) =>
    state.account != null ? JSON.parse(state.account) : null,
  funnel: (state) => state.funnel,
  funnelPublic: (state) => state.funnelPublic,
};

const actions = {
  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountPost(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountIdPut(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountIdPut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountProfilePut(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountProfilePut(payload)
        .then((response) => {
          context.commit("saveProfile", response.data.data.profile);
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountIdProfilePut(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountIdProfilePut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   */
  accountMeGet(context) {
    return new Promise((resolve, reject) => {
      Account.accountMeGet()
        .then((response) => {
          context.commit("saveAccount", response.data.data);
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountIdGet(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountIdGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   */
  accountGet() {
    return new Promise((resolve, reject) => {
      Account.accountGet()
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountSeekGet(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountSeekGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountExistsGet(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountExistsGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountInspectGet(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountInspectGet(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountRegisterAttempPost(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountRegisterAttempPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountRegisterAcceptPost(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountRegisterAcceptPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountPwdRecoverAttempPost(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountPwdRecoverAttempPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountPwdRecoverAcceptPost(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountPwdRecoverAcceptPost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountPwdChangePost(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountPwdChangePost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   * @param {*} payload
   */
  accountIdPwdOverridePost(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountIdPwdOverridePost(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  accountKYCPut(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountKYCPut(payload)
        .then((response) => {
          context.commit("saveFunnel", response.data.data.kyc);
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} param0 
   * @returns 
   */
  accountKYCGet({ commit }) {
    return new Promise((resolve, reject) => {
      Account.accountKYCGet()
        .then((response) => {
          commit("saveFunnel", response.data.data.kyc);
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  accountIdKYCPut(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountIdKYCPut(payload)
        .then(resolve)
        .catch(reject);
    });
  },

  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  accountKYCPublicGet(context, payload) {
    return new Promise((resolve, reject) => {
      Account.accountKYCPublicGet(payload)
        .then((response) => {
          context.commit("saveFunnelPublic", response.data.data.kyc);
          resolve(response);
        })
        .catch(reject);
    });
  },

  /**
   *
   * @param {*} context
   */
  resetAccountState(context) {
    context.commit("deleteAccount");
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  saveAccount(state, payload) {
    state.account = JSON.stringify(payload);
    sessionStorage.setItem("account", state.account);
  },

  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  saveProfile(state, payload) {
    const account = JSON.parse(state.account);
    account.profile = payload;
    state.account = JSON.stringify(account);
    sessionStorage.setItem("account", state.account);
  },

  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  saveFunnel(state, payload) {
    state.funnel = payload;
  },

  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  saveFunnelPublic(state, payload) {
    state.funnelPublic = payload;
  },

  /**
   *
   * @param {*} state
   */
  deleteAccount(state) {
    state.account = null;
    sessionStorage.clear();
    state.funnel = null;
    state.funnelPublic = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
