import Vue from 'vue'
import VueRouter from 'vue-router'
import ErrorPage from '../views/404'
import Funnel from '../views/Funnel'

Vue.use(VueRouter)

const routes = [
    {
        path: '/404',
        name: '404',
        component: ErrorPage,
        meta: {
            title: '404 | RRENOVA INTERNATIONAL',
        },
    },
    {
        path: '/:ucode',
        name: "funnel",
        component: Funnel,
        meta: {
            title: 'Funnel | RRENOVA INTERNATIONAL',
        },
    },
    {
        path: "*",
        redirect: "404",
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
