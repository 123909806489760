<template>
  <div class="copyright-area copyright-style-one variation-two">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-7 col-sm-12 col-12">
          <div class="copyright-left">
            <ul class="ft-menu link-hover">
              <li>
                <a href="https://www.rrenova.com" target="_blank">
                  {{ $t("text.official-website") }}
                </a>
              </li>
              <li>
                <a
                  :href="`https://office.rrenova.com/sign-up?ucode=${$route.params.ucode}`"
                  target="_blank"
                >
                  {{ $t("text.virtual-office") }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-5 mt_sm--20">
          <div
            class="copyright-center text-center"
            v-if="
              !lodash.isNil(funnelPublic) &&
              (!lodash.isNil(funnelPublic.facebook) ||
                !lodash.isNil(funnelPublic.twitter) ||
                !lodash.isNil(funnelPublic.instagram) ||
                !lodash.isNil(funnelPublic.linkedin) ||
                !lodash.isNil(funnelPublic.video))
            "
          >
            <ul
              class="
                social-icon social-default
                color-lessdark
                with-gradient
                justify-content-center
              "
            >
              <li v-if="!lodash.isNil(funnelPublic.facebook)">
                <a target="_blank" :href="funnelPublic.facebook">
                  <Icon name="facebook" />
                </a>
              </li>
              <li v-if="!lodash.isNil(funnelPublic.twitter)">
                <a target="_blank" :href="funnelPublic.twitter">
                  <Icon name="twitter" />
                </a>
              </li>
              <li v-if="!lodash.isNil(funnelPublic.instagram)">
                <a target="_blank" :href="funnelPublic.instagram">
                  <Icon name="instagram" />
                </a>
              </li>
              <li v-if="!lodash.isNil(funnelPublic.linkedin)">
                <a target="_blank" :href="funnelPublic.linkedin">
                  <Icon name="linkedin" />
                </a>
              </li>
              <li v-if="!lodash.isNil(funnelPublic.video)">
                <a target="_blank" :href="funnelPublic.video">
                  <Icon name="youtube" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
          <div class="copyright-right text-center text-lg-end">
            <p class="copyright-text">
              © 2021 - {{ new Date().getFullYear() }} RRENOVA INTERNATIONAL LLC.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../../icon/Icon";
import { mapGetters } from "vuex";

export default {
  name: "CopyrightTwo",
  computed: {
    ...mapGetters(["funnelPublic"]),
  },
  components: { Icon },
};
</script>