<template>
  <div class="row row--15 service-wrapper">
    <div
      class="col-lg-4 col-md-6 col-sm-6 col-12"
      v-for="(service, index) in [
        {
          image: require(`@/assets/images/business-opportunity.png`),
          title: this.$t('text.business-opportunity'),
          description: this.$t('text.business-opportunity-description'),
        },
        {
          image: require(`@/assets/images/products-and-services.png`),
          title: this.$t('text.products-and-services'),
          description: this.$t('text.products-and-services-description'),
        },
        {
          image: require(`@/assets/images/fintech-and-cryptoactives.png`),
          title: this.$t('text.fintech-and-cryptoactives'),
          description: this.$t('text.fintech-and-cryptoactives-description'),
        },
      ]"
      :key="index"
    >
      <div data-aos="fade-up" data-aos-delay="70">
        <div :class="`service ${serviceStyle} ${checkTextAlign}`">
          <div class="inner">
            <div class="image">
              <img :src="service.image" alt="Service Images" />
            </div>
            <div class="content">
              <h4 v-html="service.title"></h4>
              <p
                class="description b1 color-gray mb--0"
                v-html="service.description"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceThree",
  props: {
    serviceStyle: {
      type: String,
    },
    textAlign: {
      type: String,
      default: "left",
    },
  },
  computed: {
    checkTextAlign() {
      if (this.textAlign === "left") return "text-start";
      else if (this.textAlign === "center") return "text-center";
      else if (this.textAlign === "end") return "text-end";
      else return "";
    },
  },
};
</script>