<style>
span.language {
  display: block;
  height: 20px;
  text-align: right;
}

span.language img {
  height: 100%;
  border: 0px solid white;
  margin: 0px 4px 10px 4px;
  opacity: 1;
}

span.language img.selected {
  opacity: 0.5;
}
</style>

<template>
  <div :class="{ 'header-transparent-with-topbar': data.headerTransparency }">
    <!-- Start Header Top Area -->
    <div
      v-if="data.showTopHeader && data.topbarStyle === 1"
      class="header-top-news"
      :style="{
        'background-image': `url(${require(`@/assets/images/bg.png`)})`,
      }"
    >
      <div class="wrapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="inner">
                <div class="content">
                  <span class="rn-badge">Limited Time Offer</span>
                  <span class="news-text"
                    >Intro price. Get Doob for Big Sale -95% off.</span
                  >
                </div>
                <div class="right-button">
                  <a class="btn-read-more" href="#">
                    <span>Purchase Now <Icon name="arrow-right" /></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="icon-close">
        <button
          class="close-button bgsection-activation"
          @click.prevent="AppFunctions.addClass('.header-top-news', 'deactive')"
        >
          <Icon name="x" />
        </button>
      </div>
    </div>
    <!-- End Header Top Area -->

    <div
      v-if="data.showTopHeader && data.topbarStyle === 2"
      class="header-top-bar"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="header-right">
              <div class="address-content">
                <span class="language">
                  <template v-for="item in languages">
                    <img
                      :key="`language-${item.language}`"
                      :src="item.flag"
                      :class="
                        current_language == item.language ? 'selected' : ''
                      "
                      @click="
                        current_language != item.language
                          ? setLanguage(item.language, item.title, item.flag)
                          : null
                      "
                      :style="
                        current_language != item.language
                          ? 'cursor: pointer;'
                          : 'cursor: default;'
                      "
                    />
                  </template>
                </span>
                <template
                  v-if="
                    !lodash.isNil(funnelPublic) &&
                    !lodash.isNil(funnelPublic.whatsApp)
                  "
                >
                  <p></p>
                  <p>
                    <Icon name="phone" />
                    <span>
                      <a
                        :href="`https://wa.me/${
                          funnelPublic.whatsApp
                        }?text=${$t('text.funnel-whatsapp-message', {
                          value: funnelPublic.name,
                        })}`"
                        target="_blank"
                      >
                        {{ funnelPublic.whatsApp }}
                      </a>
                    </span>
                  </p>
                </template>
              </div>
              <div
                class="social-icon-wrapper"
                v-if="
                  !lodash.isNil(funnelPublic) &&
                  (!lodash.isNil(funnelPublic.facebook) ||
                    !lodash.isNil(funnelPublic.twitter) ||
                    !lodash.isNil(funnelPublic.instagram) ||
                    !lodash.isNil(funnelPublic.linkedin) ||
                    !lodash.isNil(funnelPublic.video))
                "
              >
                <ul class="social-icon social-default icon-naked">
                  <li v-if="!lodash.isNil(funnelPublic.facebook)">
                    <a target="_blank" :href="funnelPublic.facebook">
                      <Icon name="facebook" />
                    </a>
                  </li>
                  <li v-if="!lodash.isNil(funnelPublic.twitter)">
                    <a target="_blank" :href="funnelPublic.twitter">
                      <Icon name="twitter" />
                    </a>
                  </li>
                  <li v-if="!lodash.isNil(funnelPublic.instagram)">
                    <a target="_blank" :href="funnelPublic.instagram">
                      <Icon name="instagram" />
                    </a>
                  </li>
                  <li v-if="!lodash.isNil(funnelPublic.linkedin)">
                    <a target="_blank" :href="funnelPublic.linkedin">
                      <Icon name="linkedin" />
                    </a>
                  </li>
                  <li v-if="!lodash.isNil(funnelPublic.video)">
                    <a target="_blank" :href="funnelPublic.video">
                      <Icon name="youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Header Area -->
    <header
      :class="[
        `rn-header header-default header-not-transparent header-sticky ${data.headerClass}`,
      ]"
    >
      <div class="container position-relative">
        <div class="row align-items-center row--0">
          <template v-if="data.navAlignment === 'right'">
            <div class="col-lg-3 col-md-6 col-4">
              <Logo />
            </div>
            <div class="col-lg-9 col-md-6 col-8 position-static">
              <div class="header-right">
                <nav class="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>

                <!-- Start Mobile-Menu-Bar -->
                <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div class="hamberger">
                    <button
                      class="hamberger-button"
                      @click.prevent="
                        AppFunctions.toggleClass('.popup-mobile-menu', 'active')
                      "
                    >
                      <Icon name="menu" size="21" />
                    </button>
                  </div>
                </div>
                <!-- Start Mobile-Menu-Bar -->

                <div
                  v-if="data.showThemeSwitcherButton"
                  id="my_switcher"
                  class="my_switcher"
                >
                  <ul>
                    <li>
                      <a
                        href="javascript: void(0);"
                        @click.prevent="
                          AppFunctions.toggleClass('body', 'active-light-mode')
                        "
                      >
                        <img
                          class="light-icon"
                          src="../../../assets/images/icons/sun-01.svg"
                          alt="Sun images"
                        />
                        <img
                          class="dark-icon"
                          src="../../../assets/images/icons/vector.svg"
                          alt="Moon Images"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-if="data.navAlignment === 'left'">
            <div class="col-lg-9 col-md-6 col-4 position-static">
              <div class="header-left d-flex">
                <Logo />
                <nav class="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-8">
              <div class="header-right">
                <!-- Start Mobile-Menu-Bar -->
                <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div class="hamberger">
                    <button
                      class="hamberger-button"
                      @click.prevent="
                        AppFunctions.toggleClass('.popup-mobile-menu', 'active')
                      "
                    >
                      <Icon name="menu" size="21" />
                    </button>
                  </div>
                </div>
                <!-- Start Mobile-Menu-Bar -->

                <div
                  v-if="data.showThemeSwitcherButton"
                  id="my_switcher"
                  class="my_switcher"
                >
                  <ul>
                    <li>
                      <a
                        href="javascript: void(0);"
                        @click.prevent="
                          AppFunctions.toggleClass('body', 'active-light-mode')
                        "
                      >
                        <img
                          class="light-icon"
                          src="../../../assets/images/icons/sun-01.svg"
                          alt="Sun images"
                        />
                        <img
                          class="dark-icon"
                          src="../../../assets/images/icons/vector.svg"
                          alt="Moon Images"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </header>
    <!-- End Header Area -->

    <!-- Start Mobile Menu -->
    <MobileMenu />
    <!-- End Mobile Menu -->

    <!-- Start Theme Style -->
    <div>
      <div class="rn-gradient-circle" />
      <div class="rn-gradient-circle theme-pink" />
    </div>
    <!-- End Theme Style -->
  </div>
</template>

<script>
import Icon from "../../icon/Icon";
import MobileMenu from "./MobileMenu";
import AppFunctions from "../../../helpers/AppFunctions";
import Nav from "./Nav";
import Logo from "../../elements/logo/Logo";
import { mapGetters } from "vuex";
import i18n from "../../../i18n";

export default {
  name: "Header",
  props: {
    data: {
      default: null,
    },
  },
  components: { Logo, Nav, MobileMenu, Icon },
  data() {
    return {
      languages: [
        {
          flag: require("@/assets/flags/flag_us.png"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/flags/flag_es.png"),
          language: "es",
          title: "Español",
        },
        {
          flag: require("@/assets/flags/flag_br.png"),
          language: "pt",
          title: "Português",
        },
      ],

      current_language: i18n.locale,
      text: null,
      flag: null,
      value: null,

      AppFunctions,
    };
  },
  computed: {
    ...mapGetters(["funnelPublic"]),
  },
  methods: {
    initLanguage() {
      const navigatorLanguage = this.lodash.find(this.languages, {
        language: navigator.language.split("-")[0],
      });
      if (!this.lodash.isNil(navigatorLanguage)) {
        this.value = navigatorLanguage;
        this.setLanguage(
          this.value.language,
          this.value.title,
          this.value.flag
        );
      } else {
        this.value = this.lodash.find(this.languages, {
          language: i18n.locale,
        });
      }
      this.text = this.value.title;
      this.flag = this.value.flag;
    },
    setLanguage(locale, country, flag) {
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
    },
    toggleStickyHeader() {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 100) {
        AppFunctions.addClass(".header-default", "sticky");
      } else if (scrolled <= 100) {
        AppFunctions.removeClass(".header-default", "sticky");
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.toggleStickyHeader);
  },
  mounted() {
    this.toggleStickyHeader();
    this.initLanguage();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.toggleStickyHeader);
  },
};
</script>