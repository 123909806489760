<template>
  <ul class="mainmenu">
    <li class="has-droupdown has-menu-child-item"></li>
    <template v-if="$route.name == 'funnel'">
      <li>
        <a href="https://www.rrenova.com" target="_blank">
          {{ $t("text.official-website") }}
        </a>
      </li>
      <li>
        <a
          :href="`https://office.rrenova.com/sign-up?ucode=${$route.params.ucode}`"
          target="_blank"
        >
          {{ $t("text.virtual-office") }}
        </a>
      </li>
    </template>
  </ul>
</template>

<script>
import Icon from "../../icon/Icon";
export default {
  name: "Nav",
  components: { Icon },
};
</script>